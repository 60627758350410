<template>
    <VTabs class="app-tab-links" variant="tabs">
        <VTab
            v-for="item in links"
            :key="item.title"
            class="custom-pl-xs"
            :to="item.url"
            :active="isActive(item.url)"
            :disabled="isDisabled(item)"
        >
            {{ item.title }}
        </VTab>
    </VTabs>
</template>

<script>
    export default {
        props: {
            links: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            isActive(linkPath) {
                return this.$route.path.endsWith(linkPath);
            },
            isDisabled(item) {
                return item.disabled === true;
            }
        },
    };
</script>
<style scoped>
    .app-tab-links {
        margin-bottom: 16px;
    }
</style>
