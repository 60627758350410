<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ product.title }}</v-card-title>
                <v-card-text>
                    <ProductTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <td>Название</td>
                                    <td>Артикул</td>
                                    <td></td>
                                    <!-- <td></td> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in productCategories" :key="item.id">
                                    <td>{{ item.title }}</td>
                                    <td>{{ item.code }}</td>
                                    <td align="right">
                                        <v-icon color="primary" @click="remove(item.category_product_id)">mdi-trash-can</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="primary" class="mr-3" @click="create" v-if="filteredCategories.length">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                        <v-btn color="light" class="mr-3" @click="goBack" >Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Категория
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="editProductCategories.category_id"
                                    label="Категория"
                                    :items="filteredCategories"
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="save"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import ProductTabLinks from '@/components/products/ProductTabLinks';

    export default {
        name: 'ProductCategoriess',

        components: {
            ProductTabLinks,
        },

        data() {
            return {
                editModal: false,
                editProductCategories: {}
            };
        },

        computed: {
            ...mapState('products', {
                product: state => state.entity
            }),
            ...mapState('categories', {
                categories: state => state.entities
            }),
            productCategories() {
                return this.product && this.product.categories ? this.product.categories : [];
            },
            filteredCategories() {
                if(this.product && this.product.categories) {
                    const categoryIds = this.product.categories.map(item => item.id);
                    return this.categories.filter(item => {
                        return categoryIds.indexOf(item.id) < 0;
                    });
                }
                return this.categories;
            }
        },

        async mounted() {
            await store.dispatch('products/get', { id: this.$route.params.id });
            await store.dispatch('categories/fetch', {});
        },

        methods: {
            create () {
                this.editProductCategories = {
                    product_id: this.product.id,
                    category_id: ''
                };
                this.editModal = true;
            },
            edit(item) {
                this.editProductCategories = {
                    id: item.category_product_id,
                    product_id: this.product.id,
                    category_id: item.id
                };
                this.editModal = true;
            },
            async save() {
                const { id } = this.$route.params;
                try {
                    store.commit('categoryProducts/SET_ENTITY', this.editProductCategories);
                    await store.dispatch('categoryProducts/save', { product_id: id });
                    await store.dispatch('products/get', { id });
                    this.editModal = false;
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            async remove(category_product_id) {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('categoryProducts/delete', { product_id: id, id: category_product_id });
                    await store.dispatch('products/get', { id });
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/products/${id}/detail`);
                } else {
                    this.$router.push('/products');
                }
            },
        },
    };
</script>